import { useCallback } from "react"
import {useDropzone} from 'react-dropzone'
import { DropzoneIcon } from "./DropzoneIcon"

import "./DropZone.scss"
import { formatBytes, getFileFormat } from "./utils"
import classNames from "classnames"

export const DropZone = ({ files, setFiles, theme }) => {

    const onDrop = useCallback((acceptedFiles) => {
        setFiles([
                ...files,
                ...acceptedFiles.filter((item) => !files.find(file => item.name === file.name))
            ]);
    }, [files, setFiles]);

    const { fileRejections, getRootProps, getInputProps } = useDropzone({ onDrop, maxSize: 104857600 })

    const removeFile = (fileIndex) => {
        const newFiles = [...files];
        newFiles.splice(fileIndex, 1);

        setFiles(newFiles);    
    };
    
    return (
        <div className="files">
            <div className="dropzone" {...getRootProps()}>
                <input {...getInputProps()} />
                <DropzoneIcon />
                <span>Add file</span>
            </div>
            <div className={classNames("loaded-files", { dark: theme === 'dark' })}>
                {files.map((file, index) => (
                    <div  className="loaded-files__item-wrapper" key={index}>
                        <div className="loaded-files__delete" onClick={() => removeFile(index)}/>
                        <div  className="loaded-files__item" key={index}>
                            <span className="loaded-files__name">{file.name}</span>
                            <span className="loaded-files__type">{file.name.substring(file.name.lastIndexOf(".") + 1, file.name.length)}</span>
                            {getFileFormat(file.type)}
                            <span className="loaded-files__size">{formatBytes(file.size)}</span>
                        </div>
                    </div>
                ))}
                {fileRejections.map(({ file }, index) => (
                    <div  className="loaded-files__item-wrapper" key={index}>
                        <div  className="loaded-files__item error" key={index}>
                            <svg className="error" width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="28.5" cy="28.5" r="28" stroke="#EF5A5A"/>
                                <path d="M37 21L21 37" stroke="#EF5A5A" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M21 21L37 37" stroke="#EF5A5A" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                            <span className="loaded-files__name error">The file {file.name} is too big! Max size: 100mb</span>
                            <span className="loaded-files__size error">{formatBytes(file.size)}</span>
                        </div>
                    </div>
                ))}
            </div>
        </div>
                    
    )
}