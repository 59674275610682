import { SplitText } from "@cyriacbr/react-split-text";
import classNames from "classnames";
import { motion, useScroll } from "framer-motion";
import { useRef } from "react";
import { useTransform } from "framer-motion";
import { useWindowWidth } from "../../utils/useWindowWidth";

import "./SplitTextComponent.scss";

export const SplitTextComponent = ({ children }) => {
    const ref = useRef(null);

    const offset = useWindowWidth({
        desktop: ["end 95vh", "start 40vh"],
        tabletBig: ["end 50vh", "start 5vh"],
    })

    const { scrollYProgress } = useScroll({
        target: ref,
        offset: offset
    });

    return (
        <div ref={ref}>
            <SplitText
                LineWrapper={({ lineIndex, children: line }) => {
                    return (
                        <Element scrollYProgress={scrollYProgress} line={line} index={lineIndex} />
                    )
                }}
            >
                {children}
            </SplitText>
        </div>
    );
};


const Element = ({scrollYProgress, index, line}) => {
    const x = useTransform(scrollYProgress, [0, 1], [5*index + 2*index*index, 0]);
    const y = useTransform(scrollYProgress, [0, 1], [2*index + 5*index*index, 0]);
    // const y = useTransform(scrollYProgress, [0, 1], ([latestX, latestY]) => [latestX * latestY, 0]);
    const ref = useRef(null);
    

    const opacity = useTransform(scrollYProgress, [0, 0.01*index*index + 0.05*index + 0.1], [0, 1]);

    // const variants = {
    //     visible: { opacity: 1, transition: {
    //         delay: index * 0.3,
    //         duration: 1,
    //       }, },
    //     hidden: { opacity: 0 },
    // }

    const offset = useWindowWidth({
        desktop: ["end 70vh", "start 50vh"],
        tabletBig: ["end 70vh", "start 40vh"],
    })

    const { scrollYProgress: scrollYProgress2 } = useScroll({
        target: ref,
        offset: offset
      });

   
    return (
        <motion.div
            className={classNames("")}
            style={{ opacity: scrollYProgress2, translateX: x, translateY: y }}
            // variants={variants}
            // whileInView="visible"
            // initial="hidden"
            ref={ref}
        >
            {line}
        </motion.div>
        
    )
}