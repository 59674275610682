import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper";

import "./index.css"

const Trusted = () => {
    return (
        <div className="wrapper-trasted">
            <div className="wrapper-title">Trusted by</div>
            <Swiper
                    slidesPerView={"auto"}
                    autoplay = {{
                        delay: 100,
                        disableOnInteraction: false,
                        pauseOnMouseEnter: true
                    }}
                    modules={[Navigation, Autoplay]}
                    className="mySwiper"
                    grabCursor={true}
                    loop={true}
                    loopFillGroupWithBlank={true}
                    speed={2000}
                >
                    <SwiperSlide><div className="logo logo-cola" /></SwiperSlide>
                    <SwiperSlide><div className="logo logo-cartier" /></SwiperSlide>
                    <SwiperSlide><div className="logo logo-f1" /></SwiperSlide>
                    <SwiperSlide><div className="logo logo-toyota" /></SwiperSlide>
                    <SwiperSlide><div className="logo logo-hummer" /></SwiperSlide>
                    <SwiperSlide><div className="logo logo-cola" /></SwiperSlide>
                    <SwiperSlide><div className="logo logo-cartier" /></SwiperSlide>
                    <SwiperSlide><div className="logo logo-f1" /></SwiperSlide>
                    <SwiperSlide><div className="logo logo-toyota" /></SwiperSlide>
                    <SwiperSlide><div className="logo logo-hummer" /></SwiperSlide>
                </Swiper>

                <Swiper
                    slidesPerView={"auto"}
                    autoplay = {{
                        delay: 1000,
                        disableOnInteraction: false,
                        pauseOnMouseEnter: true,
                        reverseDirection: true,
                    }}
                    modules={[Navigation, Autoplay]}
                    className="mySwiper"
                    grabCursor={true}
                    loop={true}
                    speed={1000}
                >
                    <SwiperSlide><div className="logo logo-adidas" /></SwiperSlide>
                    <SwiperSlide><div className="logo logo-chanel" /></SwiperSlide>
                    <SwiperSlide><div className="logo logo-mcdonalds" /></SwiperSlide>
                    <SwiperSlide><div className="logo logo-wienerberger" /></SwiperSlide>
                    <SwiperSlide><div className="logo logo-mercedes" /></SwiperSlide>
                    <SwiperSlide><div className="logo logo-adidas" /></SwiperSlide>
                    <SwiperSlide><div className="logo logo-chanel" /></SwiperSlide>
                    <SwiperSlide><div className="logo logo-mcdonalds" /></SwiperSlide>
                    <SwiperSlide><div className="logo logo-wienerberger" /></SwiperSlide>
                    <SwiperSlide><div className="logo logo-mercedes" /></SwiperSlide>
                </Swiper>
        </div>
    )
}

export default Trusted