import { useCallback, useEffect, useState } from "react";
import { useLandscape } from "./useLandscape";

export const useInitialHeight = () => {
    const [initialHeight, setInitialHeight] = useState(window.innerHeight);
    
    const isLandscape = useLandscape();

    const updateHeight = useCallback(() => {
        setInitialHeight(window.innerHeight);
    }, [])

    useEffect(() => {
        updateHeight();
    }, [isLandscape, updateHeight]);

    return initialHeight

}