import { SplitText } from "@cyriacbr/react-split-text";
import classNames from "classnames";
import { useInView } from "framer-motion";
import { memo, useEffect, useRef } from "react";
import { Parallax } from "react-scroll-parallax";
import { changeBlockHeightOnScroll } from "../../../utils/changeBlockHeightOnScroll";
import "./index.scss"
import { SplitTextComponent } from "../../../components/SplitText/SplitTextComponent";

const Cola = () => {
    const colaRef = useRef(null);
    // const isVideoInView = useInView(wrapperVideoRef, { once: true });

    useEffect(() => {
        window.addEventListener("scroll", () => {
          changeBlockHeightOnScroll(colaRef.current, {
            desktop: [100, 500],
            tabletBig: [100, 500],
            tabletSmall: [100, 380],
            phoneBig: [100, 280],
            phoneSmall: [100, 200],
          });
        });
      }, []);

    return (
        <div className="cola-project">
            <div className="wrapper-overlay">
                <div className="cola-video">
                    <div ref={colaRef} className={classNames("cola-video__wrapper")}>
                        <div className="cola-image" />
                    </div>
                </div>
                <Text />
            </div>
        </div>
    )
}

export default Cola


const Text = memo(() => {
    const textRef = useRef(null);

    const emptyRef = useRef(null);

    useEffect(() => {
        if (!emptyRef.current) {
            window.dispatchEvent(new Event('resize'));
            emptyRef.current = true;
        }
    }, [])

    let translateY = [200, 0];
    let textTranslateY = [150, 0];
    translateY = [50, 0];
        textTranslateY = [40, 0]

    if (window.innerWidth <= 460) {
        translateY = [50, -20];
        textTranslateY = [0, -50];
    } else if (window.innerWidth <= 768 && window.innerWidth > 460) {
        translateY = [0, -120];
        textTranslateY = [50, -110];
    } else if (window.innerWidth <= 1024 && window.innerWidth > 768) {
        translateY = [0, -50];
    } else if (window.innerWidth < 1440 && window.innerWidth > 1024) {
        translateY = [90, 0];
        textTranslateY = [40, 0]
    }

    return (
        <Parallax translateY={translateY} speed={20} className="wrapper-text">
            <div className="title">Coca-Cola</div>
            <div   className="description"  ref={textRef}>
                <SplitTextComponent>
                    A dynamic freeze frame using special caustic lighting, detailed motion blur and depth of field blending to create a three-dimensional image for printed products and subsequent replication in the company.
                </SplitTextComponent>
            </div>
    </Parallax>
    )
})