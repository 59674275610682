import cn from "classnames"
import axios from "axios";
import { Element } from 'react-scroll'
import { useForm } from "react-hook-form";
import classNames from "classnames";
import { Fragment, useCallback, useEffect, useState } from "react"
import { DropZone } from "./DropZone/DropZone"
import { ResultMessage } from "./ResultMessage/ResultMessage";

import "./index.scss"

const ContactUs = ({ theme }) => {
    const {
        register,
        handleSubmit,
        watch,
        formState: { isValid, errors },
        getValues,
        setValue,
        reset
    } = useForm();

    const [files, setFiles] = useState([])
    const [isSending, setIsSending] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isResultShown, setIsResultShown] = useState(false);
    const [isError, setIsError] = useState(false);

    watch("numberOfEmployees");
    watch("numberOfClients");
    watch((data) => {
        const isChanged = Object.keys(data).some(item => !!data[item]);

        isChanged && setIsResultShown(false)
    })

    const closeResult = useCallback(() => setIsResultShown(false), []);

    const onSubmit = (submitData) => {
        console.log('%c⧭', 'color: #00258c', submitData);
        if (!isValid) {
            setIsResultShown(false);
            document.getElementById("email").scrollIntoView({ behavior: "smooth", block: "center" });

            return;
        }

        const data = new FormData();
        Object.keys(submitData).forEach(key => data.append(key, submitData[key]));

        const date = new Date();
        const minutes = date.getMinutes().length === 1 ? `0${date.getMinutes()}` : date.getMinutes()
        const getTimeZone = () => {
            var offset = date.getTimezoneOffset(), o = Math.abs(offset);
            return "UTC " +(offset < 0 ? "+" : "-") + ("00" + Math.floor(o / 60)).slice(-2) + ":" + ("00" + (o % 60)).slice(-2);
        }

        data.append("date", JSON.stringify({
            year: date.getFullYear(),
            time: `${date.getHours()}:${minutes} ${getTimeZone()}`,
            month: date.getMonth(),
            day: date.getDate(),
        }))

        files.forEach(file => {
            data.append("file", file, file.name);
        });

        setIsSending(true);
        // const url = "http://0.0.0.0:8090/api/send-contacts" //dev
        const url = "https://www.xcreative.ai/api/send-contacts";

        axios
            .post(url, data)
            .then((response) => {
                setIsResultShown(true);
                setIsSending(false);
                setIsSubmitted(true);
            })
            .catch((error) => {
                console.log('%c⧭', 'color: #e50000', error);

                setIsSubmitted(true);
                setIsSending(false);
                setIsResultShown(true);
                setIsError(true);
            });

        document.getElementById("first-name").scrollIntoView({ behavior: "smooth", block: "center" });

        console.log({
            firstName: submitData.firstName,
            lastName: submitData.lastName,
            email: submitData.email,
            website: submitData.website,
            numberOfEmployees: submitData.numberOfEmployees,
            numberOfClients: submitData.numberOfClients,
            requestedInformation: submitData.requestedInformation,
            file: files,
            date: JSON.stringify({
                year: date.getFullYear(),
                time: `${date.getHours()} : ${date.getMinutes()}`,
                month: date.getMonth(),
                day: date.getDay(),
            }),
        });
    }

    useEffect(() => {
        if (isSubmitted) {
          reset({
            firstName: "",
            lastName: "",
            email: "",
            website: "",
            numberOfEmployees: "",
            numberOfClients: "",
            requestedInformation: "",
          });

          setValue('fieldArray', [{ email: "" }, { website: "" }]);
          setFiles([]);
        }
      }, [isSubmitted, reset, setValue]);

    const handleChangeNumberOfEmployees = useCallback((e) => {
        if (e.target.value === getValues("numberOfEmployees")) {
            setValue("numberOfEmployees", "");
        }
    }, [getValues, setValue])

    const handleChangeNumberOfClients = useCallback((e) => {
        if (e.target.value === getValues("numberOfClients")) {
            setValue("numberOfClients", "");
        }
    }, [getValues, setValue])

    return (
        <Element name="contacts" className="contacts-wrapper" id="contacts">
            <h1 className="contacts-title">
                Partner With<br/>XCreative
                </h1>
            <div className="contacts-container">
                <div className="contacts-description">
                    <p className="contacts-description__text">
                    At XCreative, we are constantly innovating our technology to build the most advanced Artificial Intelligence-driven services available. We continue to partner with like-minded businesses to provide the most comprehensive creative solutions.
                    </p>
                    <p className="contacts-description__text">
                    If you think XCreative is the right fit for your business, please reach out to us to get started:
                    </p>
                </div>
                <div id="contact-form" className="contacts-form">
                    {isSubmitted && isResultShown && <ResultMessage isError={isError} onClose={closeResult} />}

                    <div className={classNames("contacts__field ", { withInfoIcon: errors.firstName})}>
                        <input
                            id="first-name"
                            type="text"
                            className={classNames("contacts__input ", { error: errors.firstName })}
                            placeholder="First Name"
                            {...register("firstName", { required: true })}
                        />
                        {errors.firstName && <span className="input-error-message">Please, Enter Your First Name</span>}
                    </div>
                    
                    <div className={classNames("contacts__field ", { withInfoIcon: errors.lastName})}>
                        <input
                            id="last-name" 
                            type="text"
                            className={classNames("contacts__input", { error: errors.lastName})}
                            placeholder="Last Name"
                            {...register("lastName", { required: true })}
                        />
                        {errors.lastName && <span className="input-error-message">Please, Enter Your Last Name</span>}
                    </div>
                   
                    <div className={classNames("contacts__field ", { withInfoIcon: errors.email})}>
                        <input
                            id="email"
                            type="text"
                            className={classNames("contacts__input", { error: errors.email})}
                            placeholder="Your Email"
                            {...register("email", {
                                required: true,
                                pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/i,
                            })}
                        />
                        {errors.email && <span className="input-error-message">Please, Enter Your Email</span>}
                    </div>
                    
                    <div className={classNames("contacts__field ", { withInfoIcon: errors.website})}>
                        <input 
                            id="website"
                            type="text"
                            className={classNames("contacts__input", { error: errors.website})}
                            placeholder="Your Website"
                            {...register("website", {
                                required: true,
                                pattern: /^[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&//=]*)$/i,
                            })}
                        />
                        {errors.website && <span className="input-error-message">Please, Enter Your Website</span>}
                    </div>
                    
                    <div className="contacts__field">
                        <div className="contacts__field-name">Number of Employees</div>
                        <div className="contacts__radio-fields">
                            {["1-5", "5-10", "10-25", "25-50", "50+"].map(item => (
                                <Fragment key={item}>
                                    <input
                                        type="radio"
                                        id={`number-of-employees-${item}`}
                                        name="number-of-employees"
                                        value={item}
                                        onClick={handleChangeNumberOfEmployees} 
                                        {...register("numberOfEmployees", { required: true  })}
                                    />
                                    <label
                                        className={cn("contacts__radio-label", { 
                                            checked: getValues("numberOfEmployees") === item,
                                            error: errors.numberOfEmployees
                                        })}
                                        htmlFor={`number-of-employees-${item}`}
                                    >
                                        {item}
                                    </label>
                                </Fragment>
                            ))}
                        </div>
                    </div>

                    <div className="contacts__field">
                        <div className="contacts__field-name">Number of Clients</div>
                        <div className="contacts__radio-fields">
                            {["0", "1-3", "3-5", "5-10", "10+"].map(item => (
                                <Fragment key={item}>
                                    <input 
                                        type="radio"
                                        id={`number-of-clients-${item}`}
                                        name="number-of-clients"
                                        value={item}
                                        onClick={handleChangeNumberOfClients}
                                        {...register("numberOfClients", { required: true  })}
                                    />
                                    <label
                                        className={cn("contacts__radio-label", {
                                            checked: getValues("numberOfClients") === item,
                                            error: errors.numberOfClients
                                        })}
                                        htmlFor={`number-of-clients-${item}`}
                                    >
                                        {item}
                                    </label>
                                </Fragment>
                            ))}
                        </div>
                    </div>

                    <div className="contacts__field">
                        <div className="contacts__field-name">Requested Information</div>
                        <div className={classNames("contacts__textarea-wrapper", { error: errors.requestedInformation })}>
                            <textarea
                                className={classNames("contacts__textarea")}
                                id="requested-information"
                                name="requested-information"
                                {...register("requestedInformation", { required: true  })}
                            />
                        </div>
                    </div>
                   
                    <div className="contacts__field">
                        <DropZone files={files} setFiles={setFiles} theme={theme} />
                    </div>
                   
                   <button className="contacts__submit-button" type="submit" onClick={handleSubmit(onSubmit)}>
                        {
                            isSending 
                                ? <span className="loader"></span>
                                : (
                                    <>
                                        <span>Submit Form</span>
                                        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M2.44043 14.62L27.5592 14.619M27.5592 14.619L19.416 6.50391M27.5592 14.619L19.416 22.7341" stroke="white" strokeWidth="2"/>
                                        </svg>
                                    </>
                                )
                        }
                    </button>
                </div>
            </div>
        </Element>
    )
}

export default ContactUs