import { SplitText } from "@cyriacbr/react-split-text"
import classNames from "classnames"
import { useInView } from "framer-motion"
import { useEffect, useRef } from "react"
import { Parallax } from "react-scroll-parallax"
import Video from "../../../ui/Video/index"
import { changeBlockHeightOnScroll } from "../../../utils/changeBlockHeightOnScroll"

import "./index.scss"
import { SplitTextComponent } from "../../../components/SplitText/SplitTextComponent"

const URL_VIDEO = "media/video/adidasVideo.mp4"
    const POSTER_URL = "media/video/back/adidas-back.jpg"

const Adidas = () => {
    const textRef = useRef(null);
    const adidasRef = useRef(null);
    const isTextInView = useInView(textRef, { once: true });

    const emptyRef = useRef(null);

    useEffect(() => {
        if (!emptyRef.current) {
            window.dispatchEvent(new Event('resize'));
            emptyRef.current = true;
        }
    }, [])

    let translateY = [100, -50];
    let textTranslateY = [0, -150];

    if (window.innerWidth <= 460) {
        translateY = [0, -100];
        textTranslateY = [-20, -150]
    } else if (window.innerWidth <= 768 && window.innerWidth > 460) {
        translateY = [100, 0];
        textTranslateY = [100, -25];
    } else if (window.innerWidth <= 1024 && window.innerWidth > 768) {
        translateY = [100, 0];
        textTranslateY = [100, -50]
    } else if (window.innerWidth < 1440 && window.innerWidth > 1024) {
        translateY = [90, 0];
        textTranslateY = [40, 0]
    }

    useEffect(() => {
        window.addEventListener("scroll", () => {
          changeBlockHeightOnScroll(adidasRef.current, {
            desktop: [100, 692],
            tabletBig: [100, 692, 200],
            tabletSmall: [100, 600, 200],
            phoneBig: [100, 500],
            phoneSmall: [100, 400, 200],
          });
        });
      }, []);

    return (
        <div className="adidas-project">
            <div className="wrapper-overlay">
                <div className="adidas-video">
                    <div ref={adidasRef} className={classNames("adidas-video__wrapper", { 
                        // animated: isVideoInView 
                        })}>
                        <Video
                            url={URL_VIDEO}
                            posterUrl={POSTER_URL}
                        >
                            <Parallax translateY={translateY} speed={10} className="title title-pseudo">
                                Adidas
                            </Parallax>
                            <Parallax translateY={textTranslateY} speed={10}  className="description description-pseudo">
                                <SplitTextComponent>
                                    Video for modern media with an unusual visualization of a running character in new sneakers. Designed in a light world with a dynamic camera for better product demonstration and marketing. 
                                </SplitTextComponent>
                            </Parallax>
                        </Video>
                    </div>
                </div>
                    <div className="text" ref={textRef}>
                        <Parallax translateY={translateY} speed={10}  className="title">Adidas</Parallax>
                            <Parallax translateY={textTranslateY} speed={20}  className="description">
                                <SplitTextComponent>
                                    Video for modern media with an unusual visualization of a running character in new sneakers. Designed in a light world with a dynamic camera for better product demonstration and marketing. 
                                </SplitTextComponent>
                            </Parallax>
                        
                    </div>
                </div>
            </div>
    )
}

export default Adidas