import { ImageFile } from "./files/ImageFile"
import { VideoFile } from "./files/VideoFile"
import { DocFile } from "./files/DocFile"

export const formatBytes = (bytes, decimals = 2) => {
    if (!+bytes) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export const getFileFormat = (format) => {
    const matchedValue = format.match(/image|video|application/)?.[0];

    switch (matchedValue) {
        case "application":
            return <DocFile/>    
        case "image":
            return <ImageFile/>    
        case "video":
            return <VideoFile/>  
        default:
            return <DocFile/>    
    }
}