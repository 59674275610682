import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper";
import { Element } from 'react-scroll'

import "swiper/css";

import "./index.css"

const ServicesPage = () => {
    return(
        <Element name="services" className="wrapper-services">
            <div className="top-text text">Our</div>
                <Swiper
                    slidesPerView={"auto"}
                    spaceBetween={60}
                    autoplay = {{
                        delay: 1000,
                        disableOnInteraction: false,
                        pauseOnMouseEnter: true
                    }}
                    modules={[Navigation, Autoplay]}
                    className="mySwiper"
                    grabCursor={true}
                    loop={true}
                    speed={1000}
                >
                    <SwiperSlide>3D Animation</SwiperSlide>
                    <SwiperSlide>3D Visualization & Render</SwiperSlide>
                    <SwiperSlide>Product Design</SwiperSlide>
                    <SwiperSlide>Video Production</SwiperSlide>
                    <SwiperSlide>Graphic Design</SwiperSlide>
                    <SwiperSlide>3D Animation</SwiperSlide>
                    <SwiperSlide>3D Visualization & Render</SwiperSlide>
                    <SwiperSlide>Product Design</SwiperSlide>
                    <SwiperSlide>Video Production</SwiperSlide>
                    <SwiperSlide>Graphic Design</SwiperSlide>
                    <SwiperSlide>3D Animation</SwiperSlide>
                    <SwiperSlide>3D Visualization & Render</SwiperSlide>
                    <SwiperSlide>Product Design</SwiperSlide>
                    <SwiperSlide>Video Production</SwiperSlide>
                    <SwiperSlide>Graphic Design</SwiperSlide>
                </Swiper>
                <div className="bottom-text text">Services</div>
        </Element>
    )
}

export default ServicesPage