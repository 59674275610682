import { useEffect, useRef } from "react";
import { Parallax } from "react-scroll-parallax";
import Video from "../../../ui/Video/index";
import { changeBlockHeightOnScroll } from "../../../utils/changeBlockHeightOnScroll";
import { SplitTextComponent } from "../../../components/SplitText/SplitTextComponent";

import "./index.scss";

const URL_VIDEO = "media/video/brusnika.mp4";
const POSTER_URL = "media/video/back/brusnika-back.jpg";

const Brusnika = () => {
  const brusnikaRef = useRef(null);
  const textRef = useRef(null);
  const emptyRef = useRef(null);

  useEffect(() => {
    if (!emptyRef.current) {
      window.dispatchEvent(new Event("resize"));
      emptyRef.current = true;
    }
  }, []);

  let translateY = [50, -50];
  let textTranslateY = [50, -50];

  if (window.innerWidth <= 460) {
    // translateY = [0, -120];
    textTranslateY = [50, -50];
  } else if (window.innerWidth <= 768 && window.innerWidth > 460) {
    // translateY = [0, -150];
    // textTranslateY = [50, -110];
  } else if (window.innerWidth <= 1024 && window.innerWidth > 768) {
    // translateY = [0, -100];
    // textTranslateY = [50, -100];
  }

  useEffect(() => {
    window.addEventListener("scroll", () => {
      changeBlockHeightOnScroll(brusnikaRef.current, {
        desktop: [100, 474],
        tabletBig: [100, 474],
        tabletSmall: [100, 380],
        phoneBig: [100, 280],
        phoneSmall: [100, 200],
      });
    });
  }, []);

  // useEffect(() => {
    
  //   if (splitTextRef.current) {
  //     window.addEventListener("scroll", () => showSplitTextOnScroll(splitTextRef.current.children));
  //   }
  // }, [isTextInView]);

  return (
    <div className="brusnika-project">
      <div className="wrapper-overlay">
        <div className="rolling-wrapper">
          <div ref={brusnikaRef}>
            <Video url={URL_VIDEO} posterUrl={POSTER_URL} animated />
          </div>
        </div>

        <div className="wrapper-text" ref={textRef}>
          <Parallax translateY={translateY} speed={10} className="title">
            <span>Brusnika</span>
            <span> Company</span>
          </Parallax>

          <Parallax
            translateY={textTranslateY}
            speed={10}
            className="description"
          >
            <SplitTextComponent>
              Designed to perfection. The very detailed photorealistic video is
              modeled down to the smallest detail with an example of life for
              the projection of the area before construction.
            </SplitTextComponent>
          </Parallax>
        </div>
      </div>
    </div>
  );
};

export default Brusnika;
