import { Element } from 'react-scroll'
import Mers from "./Mers"
import Brusnika from "./Brusnika"
import Adidas from "./Adidas"
import Cola from "./Cola"

import "./index.scss"

const Projects = () => {
    return (
        <Element name="projects" className="wrapper-projects">
            <Mers />
            <div className="pseudo-background-black pseudo-background" >
                <div className="first-background"/>
                <div className="second-background"/>
            </div>
            <Brusnika />
            <Adidas />
            <div className="pseudo-background-white pseudo-background" >
                <div className="first-background"/>
                <div className="second-background"/>
            </div>
            <Cola />
        </Element>
    )
}

export default Projects