import { useEffect, useRef, useState } from "react"
import classNames from "classnames";
import { ParallaxProvider } from 'react-scroll-parallax';

import Header from "./Header"
import Footer from "./Footer"
import Master from "./pages/Master"
import ContactUs from './pages/ContactsUs';

import './App.css';
import useScreenOrientation from "./utils/useOrientation";

function App() {
  const intervalRef = useRef(null);
  const [theme, setTheme] = useState("light");


  const updateThemeByTime = () => {
    const date = new Date()
    const hours = date.getHours();

    const isDarkThemeTime = 17 <= hours || hours < 4;

    setTheme(isDarkThemeTime ? "dark" : "light");
  }

  useEffect(() => {
    updateThemeByTime();

    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }

    intervalRef.current = setInterval(updateThemeByTime, 60000);
  }, [])

  useScreenOrientation();

  return (
    <ParallaxProvider>
      <div className={classNames("app", `app ${theme}-theme`)}>
        <Header
          theme={theme}
        />
        <Master theme={theme} />
        <ContactUs theme={theme} />
        <Footer />
      </div>
    </ParallaxProvider>
  );
}

export default App;
