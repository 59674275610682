import "./ResultMessage.css"

export const ResultMessage = ({ isError = false, onClose }) => {
    return (
        <div className="result">
            <svg onClick={onClose} className="result__close" width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="12.5" cy="12.5" r="12.5" fill="black" fillOpacity="0.2"/>
                <path d="M15.75 9.25L9.25 15.75" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M9.25 9.25L15.75 15.75" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
            {
                isError ? (
                    <div className="result__error">
                        <svg width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="28.5" cy="28.5" r="28" stroke="#EF5A5A"/>
                            <path d="M37 21L21 37" stroke="#EF5A5A" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M21 21L37 37" stroke="#EF5A5A" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        <span>Sending Error</span>
                    </div>
                ) : (
                    <div className="result__success">
                        <svg width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="28.5" cy="28.5" r="28" stroke="#6ECB5F"/>
                            <path d="M39.6666 21L24.9999 35.6667L18.3333 29" stroke="#6ECB5F" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        <span>Successfully Sent!</span>
                    </div>
                )
            }
        </div>
    )
}