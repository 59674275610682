import { memo } from 'react';

import MainPage from '../Main';
import SecondPage from "../Second"
import ServicesPage from "../Services"
import ProjectsPage from "../Projects"
import Trusted from "../Trusted"

const MasterPage = memo(({ hidden, theme }) => {
    if (hidden) return null

    return (
        <>
            <MainPage theme={theme} />
            <SecondPage />
            <ServicesPage />
            <ProjectsPage />
            <Trusted />
        </>
    )
})

export default MasterPage