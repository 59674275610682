// import { useEffect, useRef } from "react"
import classNames from "classnames"
import { memo, useEffect, useRef } from "react"
import { Parallax } from "react-scroll-parallax"
import Video from "../../../ui/Video/index"
import { changeBlockHeightOnScroll } from "../../../utils/changeBlockHeightOnScroll"

import "./index.scss"
import { SplitTextComponent } from "../../../components/SplitText/SplitTextComponent"

const URL_VIDEO = "media/video/merc.mp4"
const POSTER_URL = "media/video/back/mercedes-back.jpg"

const Mercedes = () => {
    const mersRef = useRef(null);
    // const isVideoInView = useInView(wrapperVideoRef, { once: true });

    let translateY = [0, -50];
    let textTranslateY = [25, 0];
    
    if (window.innerWidth <= 460) {
        translateY = [25, -150];
    }

    if (window.innerWidth <= 375) {
        translateY = [50, -100];
        textTranslateY = [0, -20]
    } else if (window.innerWidth <= 480 && window.innerWidth > 375) {
        translateY = [50, 0];
        textTranslateY = [50, 0]
    } else if (window.innerWidth <= 768 && window.innerWidth > 480) {
        translateY = [100, -50];
        textTranslateY = [100, -25];
    } else if (window.innerWidth <= 1024 && window.innerWidth > 768) {
        translateY = [20, -100];
        textTranslateY = [70, -50];
    } else if (window.innerWidth < 1440 && window.innerWidth > 1024) {
        translateY = [0, -100];
        textTranslateY = [30, -30];
    }

    useEffect(() => {
        window.addEventListener("scroll", () => {
          changeBlockHeightOnScroll(mersRef.current, {
            desktop: [100, 427],
            tabletBig: [100, 427],
            tabletSmall: [100, 314],
            phoneBig: [100, 280],
            phoneSmall: [100, 200],
          });
        });
      }, []);

    return (
        <div className="mers-project">
            <div className="wrapper-overlay">
                <div className="mercedes-video">
                        <div ref={mersRef} className={classNames("mercedes-video__wrapper")}>
                            <Video 
                                url={URL_VIDEO}
                                posterUrl={POSTER_URL}
                                animated
                            >
                                <Parallax translateY={translateY} speed={10} className="title title-pseudo">
                                    Mercedes-Benz
                                </Parallax>
                                
                            </Video>
                        </div>
                </div>
                <Text translateY={translateY} textTranslateY={textTranslateY}/>
            </div>
        </div>
    )
}

export default Mercedes


const Text = memo(({ translateY, textTranslateY }) => {
    return (
        <div className="text">
            <Parallax translateY={translateY} speed={10}  className="title">Mercedes-Benz</Parallax>
            <Parallax translateY={textTranslateY} speed={10}  className="description">
                <SplitTextComponent>
                    Professional shots and editing. This Video was developed for the presentation of the S-Class. A branded transition for the company was also developed, as well as UX/UI interface elements for the car.
                </SplitTextComponent>
            </Parallax>
    </div>
    )
})