import classNames from "classnames";
import { memo, useEffect, useRef } from "react";
import { changeBlockHeightOnScroll } from "../../utils/changeBlockHeightOnScroll";
import { SplitTextComponent } from "../../components/SplitText/SplitTextComponent";

import "./index.scss";

const SecondPage = () => {
  const imageWrapperRef = useRef(null);
//   const isImageInView = useInView(imageWrapperRef, { once: true });

  useEffect(() => {
    window.addEventListener("scroll", () => {
      changeBlockHeightOnScroll(imageWrapperRef.current, {
        desktop: [100, 450],
        tabletBig: [100, 450],
        tabletSmall: [100, 380],
        phoneBig: [100, 280],
        phoneSmall: [100, 200]
      }, true);
    });
  }, []);

  return (
    <div className="wrapper-secondPage" id="wrapper-second-page">
      <div className="wrapper-overlay">
        <div className="wrapper-text">
        <div className="team-text">
          <SplitTextComponent>
            Our bespoke services enable us to create tailored strategies and content
            that cater to your specific business or client needs. With experience
            working with some of the biggest brands, we have the expertise to
            produce high-quality content and effective marketing campaigns.
          </SplitTextComponent>
        </div>
        <div className="benefit-text">
          <SplitTextComponent>

          We are leveraging AI to enhance our services and stay ahead of the
            competition. Our team utilizes cutting-edge technologies for data
            analysis, market research, and personalized experiences. By integrating
            AI into our processes, we deliver faster and more accurate results,
            helping you make data-driven decisions for growth. Harness the power of
            AI with us and take your business to new heights.
          </SplitTextComponent>
        </div>
        </div>
        <div className="secondPage-image">
          <div
            ref={imageWrapperRef}
            className={classNames("secondPage-image__wrapper", {
              // animated: isImageInView
            })}
            id="my-block"
          >
            <div className={classNames("secondPage-image__item")} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SecondPage;

const TeamText = memo(() => {
  const textRefFirst = useRef(null);

  return (
    <div className="team-text" ref={textRefFirst}>
      <SplitTextComponent>
        Our bespoke services enable us to create tailored strategies and content
        that cater to your specific business or client needs. With experience
        working with some of the biggest brands, we have the expertise to
        produce high-quality content and effective marketing campaigns.
      </SplitTextComponent>
    </div>
  );
});

const AnotherText = memo(() => {
  const textRefSecond = useRef(null);

  return (
    <div className="benefit-text" ref={textRefSecond}>
      <SplitTextComponent>

      We are leveraging AI to enhance our services and stay ahead of the
        competition. Our team utilizes cutting-edge technologies for data
        analysis, market research, and personalized experiences. By integrating
        AI into our processes, we deliver faster and more accurate results,
        helping you make data-driven decisions for growth. Harness the power of
        AI with us and take your business to new heights.
      </SplitTextComponent>
    </div>
  );
});
